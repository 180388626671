// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArDescriptionList-module--ar-gradient-blue--I6IGH";
export var arGradientLight = "ArDescriptionList-module--ar-gradient-light--tklks";
export var arH1 = "ArDescriptionList-module--ar-h1--DFG+K";
export var arH1Mobile = "ArDescriptionList-module--ar-h1-mobile--lK6VN";
export var arH2 = "ArDescriptionList-module--ar-h2--xRo1d";
export var arH2Mobile = "ArDescriptionList-module--ar-h2-mobile--A9NcL";
export var arH3 = "ArDescriptionList-module--ar-h3--qK7LD";
export var arH3Mobile = "ArDescriptionList-module--ar-h3-mobile--41Aox";
export var arH4 = "ArDescriptionList-module--ar-h4--x5sxQ";
export var arH4Mobile = "ArDescriptionList-module--ar-h4-mobile--tivPZ";
export var arH5 = "ArDescriptionList-module--ar-h5---Bn9S";
export var arH5Mobile = "ArDescriptionList-module--ar-h5-mobile--waNtu";
export var arLinkDesktop = "ArDescriptionList-module--ar-link-desktop--aimX+";
export var arLinkMobile = "ArDescriptionList-module--ar-link-mobile--JUUuv";
export var arParagraphBase = "ArDescriptionList-module--ar-paragraph-base--Bxg5s";
export var arParagraphBaseMedium = "ArDescriptionList-module--ar-paragraph-base-medium--xhLfC";
export var arParagraphSmall = "ArDescriptionList-module--ar-paragraph-small--EL5ky";
export var arParagraphSmallMedium = "ArDescriptionList-module--ar-paragraph-small-medium--LtafR";
export var arParagraphXl = "ArDescriptionList-module--ar-paragraph-xl--UD-Sn";
export var arParagraphXlMedium = "ArDescriptionList-module--ar-paragraph-xl-medium--FvK7x";
export var arParagraphXsmall = "ArDescriptionList-module--ar-paragraph-xsmall--KG5SB";
export var arParagraphXsmallMedium = "ArDescriptionList-module--ar-paragraph-xsmall-medium--5bk4W";
export var arQuote = "ArDescriptionList-module--ar-quote--FuA+Y";
export var arQuoteMobile = "ArDescriptionList-module--ar-quote-mobile--l+ICo";
export var arTransition = "ArDescriptionList-module--ar-transition--ScR3b";
export var container = "ArDescriptionList-module--container--ZZuxt";
export var description = "ArDescriptionList-module--description--o9JYz";