// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArTextHero-module--ar-gradient-blue---uAlU";
export var arGradientLight = "ArTextHero-module--ar-gradient-light--dbP0h";
export var arH1 = "ArTextHero-module--ar-h1--oLIxF";
export var arH1Mobile = "ArTextHero-module--ar-h1-mobile--5pjzb";
export var arH2 = "ArTextHero-module--ar-h2--PRMVX";
export var arH2Mobile = "ArTextHero-module--ar-h2-mobile--qXslk";
export var arH3 = "ArTextHero-module--ar-h3--1s+0q";
export var arH3Mobile = "ArTextHero-module--ar-h3-mobile--LQWx+";
export var arH4 = "ArTextHero-module--ar-h4--WPf5X";
export var arH4Mobile = "ArTextHero-module--ar-h4-mobile--N11Iq";
export var arH5 = "ArTextHero-module--ar-h5--Oexw3";
export var arH5Mobile = "ArTextHero-module--ar-h5-mobile--QPnIe";
export var arLinkDesktop = "ArTextHero-module--ar-link-desktop--Zpm9g";
export var arLinkMobile = "ArTextHero-module--ar-link-mobile--wbQDh";
export var arParagraphBase = "ArTextHero-module--ar-paragraph-base--oR7Iy";
export var arParagraphBaseMedium = "ArTextHero-module--ar-paragraph-base-medium--1p7WG";
export var arParagraphSmall = "ArTextHero-module--ar-paragraph-small--6fEtT";
export var arParagraphSmallMedium = "ArTextHero-module--ar-paragraph-small-medium--qOQ5H";
export var arParagraphXl = "ArTextHero-module--ar-paragraph-xl--q2u-B";
export var arParagraphXlMedium = "ArTextHero-module--ar-paragraph-xl-medium--Iv+sY";
export var arParagraphXsmall = "ArTextHero-module--ar-paragraph-xsmall--ThLhw";
export var arParagraphXsmallMedium = "ArTextHero-module--ar-paragraph-xsmall-medium--0nMa4";
export var arQuote = "ArTextHero-module--ar-quote--NCttL";
export var arQuoteMobile = "ArTextHero-module--ar-quote-mobile--2TV-O";
export var arTransition = "ArTextHero-module--ar-transition--PNly6";
export var container = "ArTextHero-module--container--jsPHl";